import cx from "classnames";
import * as React from "react";
import styled from "styled-components";
import { useGroupsWithPlans } from "../../contexts/PlanManagerContext";
import {
  TaskingMapContext,
  useAutoSubmitPlans,
} from "../../contexts/TaskingMapContext";
import { getDefaultExtent } from "../../lib/map";
import { WindowManagerProvider } from "../../lib/services/WindowManager";
import { TaskingMapLayerName } from "../../lib/types";
import Flex from "../Flex";
import DrawingTools from "../FragOrderNavigation/DrawingTools";
import Navigation from "../FragOrderNavigation/Navigation";
import AirbaseLayer from "../MapCanvas/AirbaseLayer";
import ControlMeasuresLayer from "../MapCanvas/ControlMeasuresLayer";
import ControlPointsLayer from "../MapCanvas/ControlPointsLayer";
import GroupLayer from "../MapCanvas/GroupLayer";
import GroupsWithWaypoints from "../MapCanvas/GroupWithRoute";
import MapCanvas from "../MapCanvas/MapCanvas";
import MizDrawingLayer from "../MapCanvas/MizDrawingLayer";
import OPFORGroundUnits from "../MapCanvas/OPFORGroundUnits";
import OPFORStatics from "../MapCanvas/OPFORStatics";
import OpforShipLayer from "../MapCanvas/OpforShipLayer";
import PMTilesLayer from "../MapCanvas/PMTilesLayer";
import PlanningLayer from "../MapCanvas/PlanningLayer";
import SharedNavPointsLayer from "../MapCanvas/SharedNavPointsLayer";
import StickMapLayer from "../MapCanvas/StickMapLayer";
import SupportAssetLayer from "../MapCanvas/SupportAssetLayer";
import TerrainLayer from "../MapCanvas/TerrainLayer";
import ThreatLayer from "../MapCanvas/ThreatLayer";
import ZoneLayer from "../MapCanvas/ZoneLayer";
import PublicHeader from "./PublicHeader";
import VizControls from "./VizControls";

export type Props = {
  className?: string;
  height?: number;
  width?: number;
  navWidth?: number;
};

// Just an empty wrapper so we can introspect child props and organize layer controls.
function LayerControlGroup({ children }: any) {
  return children;
}

function TaskingMap({ className, navWidth }: Props) {
  const {
    state: {
      tasking,
      planningMode,
      manifest,
      disableDistances,
      disableWaypointLabels,
    },
  } = React.useContext(TaskingMapContext);

  useAutoSubmitPlans();

  if (!tasking.plannedGroups || !tasking.theater) {
    return null;
  }

  const { theater } = tasking;
  const extent = getDefaultExtent(theater);

  const { isLoading, data: groups } = useGroupsWithPlans(
    manifest.fragOrderID,
    tasking.plannedGroups
  );

  if (isLoading) {
    return null;
  }

  return (
    <div className={cx(className, { "planning-mode": planningMode })}>
      <PublicHeader />
      <Flex between>
        <WindowManagerProvider>
          <div>
            <MapCanvas
              offsetLeft={navWidth}
              theater={theater}
              initialExtent={extent}
            >
              <Navigation />
              <DrawingTools />
              <LayerControlGroup name="Layers">
                {/* <OSMLayer />/ */}
                <PMTilesLayer />
                <TerrainLayer />
                <StickMapLayer />
                <ControlMeasuresLayer />
                <AirbaseLayer
                  theater={tasking.theater}
                  bases={tasking.airbases}
                  isOpfor={false}
                />
                <AirbaseLayer
                  theater={tasking.theater}
                  bases={tasking.opforAirbases}
                  isOpfor
                />
                <GroupLayer
                  name={TaskingMapLayerName.Friendlies}
                  theater={theater}
                  groups={tasking.bluforVehicles}
                  size={18}
                />
                <GroupLayer
                  name={TaskingMapLayerName.FriendlyShips}
                  theater={theater}
                  groups={tasking.bluforShips}
                  size={18}
                />
                <OpforShipLayer
                  name={TaskingMapLayerName.OPFORShips}
                  theater={theater}
                  groups={tasking.opforShips}
                />
                <SupportAssetLayer
                  theater={theater}
                  groups={tasking.supportAssets}
                />
                <OPFORGroundUnits
                  theater={theater}
                  groups={tasking.opforVehicles}
                />
                <OPFORStatics theater={theater} groups={tasking.opforStatics} />
                <MizDrawingLayer />
                <ThreatLayer
                  theater={theater}
                  groups={[
                    ...tasking.opforVehicles,
                    ...(tasking.opforShips || []),
                  ]}
                />
                <ZoneLayer theater={theater} zones={tasking.zones} />
                <SharedNavPointsLayer
                  theater={theater}
                  bullseye={tasking.bullseye}
                />

                <ControlPointsLayer
                  theater={tasking.theater}
                  points={tasking.controlPoints}
                />
              </LayerControlGroup>

              <GroupsWithWaypoints
                layerName={TaskingMapLayerName.PlayerGroups}
                theater={theater}
                groups={groups}
                disableDistanceLabels={disableDistances}
                disableWaypointLabels={disableWaypointLabels}
              />
              <PlanningLayer />

              <VizControls />
            </MapCanvas>
          </div>
        </WindowManagerProvider>
      </Flex>
    </div>
  );
}

export default styled(TaskingMap).attrs({ className: TaskingMap.name })`
  position: relative;

  ${DrawingTools} {
    position: fixed;
    left: 50%;
    bottom: 48px;
    transform: translateX(-50%);
    z-index: 1;
  }
`;
