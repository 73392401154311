import JSZip from "jszip";
import { SupportAction } from "./mission";

export type LuaTable = {
  numValues: LuaTable[];
  strValues: {
    [key: string]: LuaTable;
  };
  get(k: string): any;
};

export enum Coalition {
  Red = "red",
  Blue = "blue",
  Neutrals = "neutrals",
}

export enum WaypointType {
  TakeOff = "TakeOff",
  TakeoffParking = "TakeOffParking",
  TurningPoint = "Turning Point",
}

export enum UnitCategory {
  Plane = "plane",
  Helicopter = "helicopter",
  Vehicle = "vehicle",
  Ship = "ship",
  Static = "static",
}

export enum AltitudeType {
  AGL = "RADIO",
  MSL = "BARO",
}

export enum WaypointAction {
  FromParkingArea = "From Parking Area",
  TurningPoint = "Turning Point",
}

export type DCSWaypoint = {
  number: number;
  ETA: number;
  ETA_locked: boolean;
  action: WaypointAction;
  airdromeId: number;
  alt: number;
  alt_type: AltitudeType;
  formation_template: string;
  name: string;
  speed: number;
  speed_locked: boolean;
  type: WaypointType;
  x: number;
  y: number;
};

export type LocalizedDCSWaypoint = {
  localizedName: string;
} & DCSWaypoint;

export enum DCSRadioModulation {
  AM = 0,
  FM = 1,
}

export type DCSChannelConfig = {
  frequency: number;
  modulation: DCSRadioModulation;
  name: string;
};

export type SharedRadioChannel = {
  channel: number;
  description: string;
} & DCSChannelConfig;

export type DCSUnit = {
  unitId: number;
  type: string;
  x: number;
  y: number;
  name: string;
  callsign?: string;
  laserCode: number[];
  radios: DCSChannelConfig[][];
  payload: string[];
  linkedUnitId: number;
  tailNumber: string;
  aircraftProps?: Record<string, boolean | number>;
  datalinks: any;
};

export type DCSGroupData = {
  category: UnitCategory;
  coalition: Coalition;
  country: string;
  name: string;
  waypoints: DCSWaypoint[];
  units: DCSUnit[];
  x: number;
  y: number;
  hidden: boolean;
  hiddenOnMFD: boolean;
  hiddenOnPlanner: boolean;
  isPlayer: boolean;
  airdromeId?: number;
  channel: DCSChannelConfig;
  supportType?: SupportAction;
  groupId: number;
  linkedUnitId?: number;
  navTargetPoints?: NavTargetPoint[];
};

export interface NavTargetPoint {
  index: number;
  textComment: string;
  x: number;
  y: number;
}

export type MissionData = {
  planes: DCSGroupData[];
};

export type MizFile = {
  name: string;
  entries: JSZip;
};

export enum AirbaseType {
  "Airfield" = "airfield",
  "Carrier" = "carrier",
  "FARP" = "farp",
  "LHA" = "lha",
}

export enum ZoneType {
  Circle = 0,
  Quad = 2,
}

export type DCSZoneData = {
  name: string;
  verticies: { x: number; y: number }[];
  color: [number, number, number, number];
  type: ZoneType;
  origin: { x: number; y: number };
  radius: number;
  hidden: boolean;
};

export enum AppRoutes {
  Home = "/",
  NewFragOrder = "/new_frag_order",
  FragOrderDetail = "/frag_order_detail",
  PreviewFragOrder = "/preview_frag_order",
  PlanningTemplates = "/planning_templates",
  MyFragOrders = "/my_frag_orders",
  Login = "/login",
  Signup = "/signup",
  Logout = "/logout",
  PublicFragOrder = "/public_frag_order",
  EditFragOrder = "/edit_frag_order",
  BatchOperations = "/batch_operations",
  DiscordOauth = "/oauth/discord",
  UserSettings = "/settings",
  Payment = "/payment",

  StripeCallback = "/stripe/callback",

  SuperAdmin = "/__super_admin__",

  StandaloneSignupsPage = "/signups",

  RedeemCode = "/redeem_code",
}

export enum FreqBand {
  UHF = "UHF",
  VHF = "VHF",
  VHF_FM = "VHF_FM",
  HF = "HF",
}

export interface RadioInteractable {
  name: string;
  UHF: number | null;
  VHF: number | null;
  AirdromeId?: number | null;
  UnitID?: number | null;
}

export const METERS_TO_NAUTICAL_MILES = 0.000539957;

export enum DCSDrawingPrimitive {
  Line = "Line",
  Polygon = "Polygon",
  TextBox = "TextBox",
}

export type DCSDrawingObject = {
  visible: boolean;
  mapY: number;
  primitiveType: DCSDrawingPrimitive;
  closed: boolean;
  thickness: number;
  colorString: string;
  style: string;
  lineMode: string;
  mapX: number;
  points: { x: number; y: number }[];
  name: string;
  layerName: string;
  polygonMode?: "rect" | "circle" | "oval";
  height?: number;
  width?: number;
  radius?: number;
  fillColorString?: string;
  text?: string;
  angle?: number;
  r1?: number;
  r2?: number;
};

export type Fetch = typeof window.fetch;

export type FirebaseMetadata = {
  id?: string;
  created_by_uid: string;
  created_at_timestamp: number;
  updated_at_timestamp: number;
};

export enum TaskingMapLayerName {
  Bases = "Bases",
  OPFORBases = "OPFOR Bases",
  Threats = "Threats",
  OPFORGroups = "OPFOR Groups",
  PlayerGroups = "Player Groups",
  SupportAssets = "Support Assets",
  Terrain = "Terrain",
  StreetMap = "Map",
  Zones = "Zones",
  Friendlies = "Friendlies",
  DrawingObjects = "Drawing Objects",
  OPFORStatics = "OPFOR Statics",
  ControlPoints = "Control Points",
  ControlMeasures = "Control Measures",
  Compass = "Compass",
  FriendlyShips = "Friendly Ships",
  OPFORShips = "OPFOR Ships",
}

export enum FeatureClass {
  FlyableGroup = "FlyableGroup",
  SupportAsset = "SupportAsset",
  Airbase = "Airbase",
  OPFORGroups = "OPFORGroups",
  Waypoint = "Waypoint",
  NavPoint = "NavPoint",
  Static = "Static",
  Bullseye = "SharedNavPoint",
  ControlPoint = "Control Point",
  ControlMeasure = "Control Measure",
}

export interface MinimalWaypoint {
  number: number;
  x: number;
  y: number;
}

export enum ScreenSize {
  Phone = 375,
  Tablet = 768,
  Laptop = 1280,
  Desktop = 1920,
}

export const ASSET_VIEWER_PARAM = "assetViewerOpen";

export type DCSNavPoint = {
  type: string;
  comment: string;
  callsignStr: string;
  id: number;
  properties?: { [key: string]: number };
  x: number;
  y: number;
};
