import _ from "lodash";
import { DateTime } from "luxon";
import * as React from "react";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { Module, modules } from "../../lib/data/modules";
import { calcFuelPlanDetails } from "../../lib/fuel";
import {
  convertDCSToLatLong,
  formatLatLong,
  metersToFeet,
} from "../../lib/map";
import {
  AircraftDetails,
  ExternalDataRecord,
  GroupDetails,
  RecordType,
} from "../../lib/models/ExternalDataRecord";
import { GroupLineupCardSettings } from "../../lib/models/Job";
import { RadioChannelOverride } from "../../lib/models/Plan";
import { FuelPlan } from "../../lib/models/PlanItem";
import { TaskingState } from "../../lib/models/PublishManifest";
import { RadioPresetConfig } from "../../lib/models/RadioPresetConfig";
import { predictRadioPresets } from "../../lib/radios";
import { distance, formatMilitaryTime, timeOnTarget } from "../../lib/time";
import { DCSUnit, DCSWaypoint } from "../../lib/types";
import DataTable, { Column } from "../DataTable";
import KneeboardLineupGroupDetails from "./KneeboadLineupGroupDetails";

type Props = {
  tasking: TaskingState;
  group: GroupWithPlan;
  hideToT?: boolean;
  settings?: GroupLineupCardSettings;
  radioConfig?: RadioPresetConfig;
  externalData?: ExternalDataRecord[];
  fuelPlan?: FuelPlan;
};

const styles = {
  table: {
    textAlign: "left",
    width: "100%",
    borderCollapse: "collapse",
    tableLayout: "fixed",
    border: "1px solid black",
  } as React.CSSProperties,
  th: {
    fontSize: 12,
    border: "1px solid black",
  } as React.CSSProperties,
  td: {
    border: "1px solid gray",
    overflow: "hidden",
    whiteSpace: "nowrap",
  } as React.CSSProperties,
  tableLabel: {
    display: "flex",
    width: "calc(100% - 1px)",
    justifyContent: "center",
    border: "1px solid black",
    borderBottom: 0,
    boxSizing: "border-box",
  } as React.CSSProperties,
};

export default function KneeboardLineupCard({
  tasking,
  group,
  hideToT,
  settings,
  radioConfig,
  externalData,
  fuelPlan,
}: Props) {
  const [local, zulu] = formatMilitaryTime(tasking);

  const type = _.first(group.units).type;
  const module: Module = modules[type];
  // const laser = new DCSPlayerGroup(group).laserCodes();

  const aircraftDataRecord = _.find(externalData, {
    type: RecordType.AircraftAdminDetails,
  });

  const externalAircraftData =
    aircraftDataRecord &&
    (JSON.parse(aircraftDataRecord?.payload) as AircraftDetails[]);

  const cols: any[] = [
    {
      value: (u, c, r) => `${group.name}-${r + 1}`,
      label: "Flight Member",
      style: { th: styles.th, td: styles.td },
    },
  ];

  const first = _.first(group.units);
  const hasLink =
    first.aircraftProps?.STN_L16 || first.aircraftProps?.OwnshipCallSign;

  let linkText;
  let isApache = false;
  if (first.aircraftProps?.STN_L16) {
    linkText = "STN";
  }

  if (first.aircraftProps?.OwnshipCallSign) {
    linkText = "DL Callsign";
    isApache = true;
  }

  if (hasLink) {
    cols.push({
      value: (u: DCSUnit) =>
        u.aircraftProps?.STN_L16 || u.aircraftProps?.OwnshipCallSign,
      label: linkText,
      style: { th: styles.th, td: styles.td },
    });
  }

  if (isApache) {
    cols.push({
      value: (u: DCSUnit) => u.aircraftProps?.TN_IDM_LB,
      label: "Originator ID",
      style: { th: styles.th, td: styles.td },
    });
  }

  if (_.keys(module?.laserConfigFields).length > 0) {
    cols.push({
      value: (u: DCSUnit, c, r) => {
        const relevant = _.find(externalAircraftData as AircraftDetails[], {
          flightNumber: r + 1,
          groupName: group.name,
        });

        if (relevant && relevant.laserCode) {
          return relevant.laserCode;
        }

        return _.compact(u.laserCode).join("");
      },
      label: "Laser Code",
      style: { th: styles.th, td: styles.td },
    });
  }

  const aircraftDetails = _.find(externalData, {
    type: RecordType.AircraftAdminDetails,
  });

  const detailsPayload = aircraftDetails
    ? (JSON.parse(aircraftDetails?.payload) as AircraftDetails[])
    : null;

  const relevantAircraftDetails = _.filter(detailsPayload, {
    groupName: group.name,
  });

  if (relevantAircraftDetails && relevantAircraftDetails.length > 0) {
    if (relevantAircraftDetails.some((d) => d.playerName)) {
      cols.push({
        value: (u: DCSUnit, colNum: number, rowNum: number) =>
          relevantAircraftDetails[rowNum]?.playerName,
        label: "Player Name",
        style: { th: styles.th, td: styles.td },
      });
    }

    cols.push({
      value: (u: DCSUnit, colNum: number, rowNum: number) => {
        const a = relevantAircraftDetails[rowNum];
        const mode1 = a?.iffMode1;
        const mode3 = a?.iffMode3;
        return mode1 ? `${mode1}/${mode3}` : "";
      },
      label: "IFF Mode 1/3",
      style: { th: styles.th, td: styles.td },
    });

    cols.push({
      value: (u: DCSUnit, colNum: number, rowNum: number) =>
        relevantAircraftDetails[rowNum]?.tailNumber,
      label: "Tail Number",
      style: { th: styles.th, td: styles.td },
    });
  }

  const groupDetails = _.find(externalData, {
    type: RecordType.GroupAdminDetails,
  });

  const groupDetailsPayload = groupDetails
    ? (JSON.parse(groupDetails?.payload) as GroupDetails[])
    : null;

  const relevantGroupDetails = _.find(groupDetailsPayload, {
    groupName: group.name,
  });

  let overrides: RadioChannelOverride[][];

  if (settings?.showFreqs) {
    if (group.plan?.radioOverrides) {
      overrides = JSON.parse(group.plan?.radioOverrides);
    }

    if (radioConfig) {
      overrides = predictRadioPresets(group, radioConfig);
    }
  }

  const radioRows = [];

  _.each(overrides, (radio, n) => {
    _.each(radio, (o, i) => {
      if (!radioRows[i]) {
        radioRows[i] = [];
      }

      radioRows[i][n] = o;
    });
  });

  const comCols = _.map(module?.radios, (r) => ({
    label: r.short,
    value: (o: RadioChannelOverride[], colN: number) => {
      const ov = o[colN - 1];

      let text = ov?.description;

      return text;
    },
    style: {
      th: styles.th,
      td: styles.td,
    },
  }));

  const waypointCols: Column[] = [
    {
      value: "number",
      label: "#",
      width: 5,
      style: {
        th: styles.th,
        td: styles.td,
      },
    },
    {
      value: (wp: DCSWaypoint) =>
        wp.name !== wp.number.toString() ? wp.name : "",
      label: "Name",
      width: 20,
      style: {
        th: styles.th,
        td: styles.td,
      },
    },
  ];

  if (settings?.showTimeOnTarget) {
    waypointCols.push({
      value: (wp: DCSWaypoint) =>
        timeOnTarget(tasking, wp.ETA).toLocaleString(
          DateTime.TIME_24_WITH_SECONDS
        ),
      label: "ToT (Zulu)",
      width: 16,
      style: {
        th: { ...styles.th, display: hideToT ? "none" : "" },
        td: { ...styles.td, display: hideToT ? "none" : "" },
      },
    });
  }

  if (settings?.showDistance) {
    waypointCols.push({
      label: "Distance (NM)",
      style: {
        th: styles.th,
        td: styles.td,
      },
      width: 10,
      value: (wp: DCSWaypoint) => {
        const last = _.find(group.waypoints, {
          number: wp.number - 1,
        });

        if (!last) {
          return "";
        }

        return distance(tasking.theater, last, wp).toFixed(1);
      },
    });
  }

  if (settings?.showAltitude) {
    waypointCols.push({
      style: {
        th: styles.th,
        td: styles.td,
      },
      width: 18,
      value: (wp: DCSWaypoint) =>
        `${metersToFeet(wp.alt).toFixed(0)}ft ${
          wp.alt_type === "BARO" ? "MSL" : "AGL"
        }`,
      label: "Altitude",
    });
  }

  if (settings?.showCoords) {
    const fn = (idx) => (wp: DCSWaypoint) => {
      const { long, lat } = convertDCSToLatLong(tasking.theater, wp);

      const coord = formatLatLong([long, lat], 3);

      return coord[idx];
    };

    const northing = {
      style: {
        th: styles.th,
        td: styles.td,
      },
      width: 20,
      value: fn(0),
      label: "Latitude",
    };

    const easting = {
      style: {
        th: styles.th,
        td: styles.td,
      },
      width: 20,
      value: fn(1),
      label: "Longitude",
    };

    const mgrs = {
      style: {
        th: styles.th,
        td: styles.td,
      },
      width: 24,
      value: fn(2),
      label: "MGRS",
    };

    waypointCols.push(northing);
    waypointCols.push(easting);
    waypointCols.push(mgrs);
  }

  let targetWP: DCSWaypoint;
  let fuel: ReturnType<typeof calcFuelPlanDetails>;
  if (fuelPlan) {
    targetWP = _.find(group.waypoints, { number: fuelPlan.targetWaypoint });
    fuel = calcFuelPlanDetails(
      module,
      tasking.theater,
      targetWP,
      group.waypoints,
      fuelPlan
    );
  }

  return (
    <div
      style={{
        margin: 8,
        fontFamily: "sans-serif",
        height: "100%",
        width: "98%",
        fontSize: 14,
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ fontSize: 18 }}>
            {group.name} - {module?.label || type} - Lineup Card
          </h2>
          <div style={{ fontSize: 14, fontWeight: "bold" }}>
            <span>
              Time (local/zulu): {local}/{zulu}
            </span>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 8 }}>
        {relevantGroupDetails && (
          <KneeboardLineupGroupDetails
            styles={styles}
            groupDetails={relevantGroupDetails}
          />
        )}
      </div>
      <div style={{ marginTop: 8 }}>
        <DataTable
          style={styles.table as any}
          columns={cols}
          rows={group.units}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          marginTop: 8,
          paddingRight: 4,
        }}
      >
        <div>
          <div style={styles.tableLabel as any}>
            <b style={{ fontSize: 12 }}>Waypoints</b>
          </div>
          <DataTable
            style={{ ...(styles.table as any) }}
            columns={waypointCols}
            rows={_.tail(group.waypoints)}
          />
          {fuelPlan && (
            <div style={{ marginTop: 8 }}>
              <div style={styles.tableLabel}>
                <b style={{ fontSize: 12 }}>Fuel Plan</b>
              </div>
              <table style={{ ...styles.table }}>
                <thead>
                  <tr style={{ border: "0px solid white", height: 0 }}>
                    <td></td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={styles.td}>
                      <b>Target WP:&nbsp;</b>
                      {fuelPlan.targetWaypoint}
                    </td>
                    <td style={styles.td}>
                      <b>Dist:&nbsp;</b>
                      {fuel.distance.toFixed(0)}NM
                    </td>
                    <td style={styles.td}>
                      <b>Time:&nbsp;</b>
                      {fuel.time.toFixed(0)}min
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.td}>
                      <b>GS: &nbsp;</b>
                      {fuelPlan.groundSpeed}
                    </td>
                    <td style={styles.td}>
                      <b>FF: &nbsp;</b>
                      {fuelPlan.fuelFlow}PPH
                    </td>
                    <td style={styles.td}>
                      <b>Bingo: &nbsp;</b>
                      {fuelPlan.bingo}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.td}>
                      <b>Loiter: &nbsp;</b>
                      {fuel.loiterTime.toFixed(0)}min
                    </td>
                    <td style={styles.td}>
                      <b>Target: &nbsp;</b>
                      {fuel.fuelAtTarget.toFixed(0)}lbs
                    </td>
                    <td style={styles.td}>
                      <b>Home: &nbsp;</b>
                      {fuel.fuelAtLanding.toFixed(0)}lbs
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div
          style={{
            paddingLeft: 4,
            display: radioRows.length === 0 ? "none" : "block",
          }}
        >
          <div style={styles.tableLabel as any}>
            <b style={{ fontSize: 12 }}>Radio Presets</b>
          </div>
          <DataTable
            style={{ ...(styles.table as any) }}
            columns={[
              {
                value: (o: RadioChannelOverride, colN: number, rowN: number) =>
                  rowN + 1,
                label: "Chan",
                width: 32,
                style: {
                  th: styles.th,
                  td: styles.td,
                },
              },
              ...comCols,
            ]}
            rows={_.take(radioRows, 20)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          border: "1px solid black",
          position: "absolute",
          right: 0,
          top: 957 - 300,
          width: (717 - 16) / 2,
          height: 300,
        }}
      >
        <div style={{ marginLeft: 4 }}>
          {/* {detailsForGroup && (
            <KneeboardLineupGroupDetails
              groupDetails={detailsForGroup}
              styles={styles}
            />
          )}

          {detailsForAircraft && detailsForAircraft.length > 0 && (
            <KneeboardLineupAircraftDetails
              details={detailsForAircraft}
              styles={styles}
            />
          )} */}

          <div>
            <b>Remarks:</b>
          </div>
          <div>
            <pre style={{ whiteSpace: "pre-wrap", width: (717 - 16) / 2 }}>
              {group.plan?.plannerNotes}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
