import { useQuery } from "react-query";

const url = "https://s3.us-west-2.amazonaws.com/tiles.fragorders.com/test.json";

export const useGetStyles = (pmtiles) => {
  return useQuery(["map_styles"], async () => {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    data.sources.protomaps.url = `pmtiles://${pmtiles}`;

    return data;
  });
};
