import { Module } from "./data/modules";
import { Theater } from "./map";
import { FuelPlan } from "./models/PlanItem";
import { cumulativeDistance } from "./time";
import { DCSWaypoint, MinimalWaypoint } from "./types";

export const calcFuelPlanDetails = (
  mod: Module,
  theater: Theater,
  targetWP: MinimalWaypoint,
  wps: DCSWaypoint[],
  fuelPlan: FuelPlan
) => {
  const distance = targetWP ? cumulativeDistance(theater, targetWP, wps) : 0;
  const time = (distance / fuelPlan.groundSpeed) * 60;

  const totalFuel =
    mod?.fuel?.internal + fuelPlan.externalTanks * mod?.fuel?.externalCapacity;

  const fuelConsumed = (fuelPlan.fuelFlow / 60) * time * mod?.fuel?.engineCount;
  const fuelAtTarget = totalFuel - fuelConsumed;

  const loiterGas = fuelAtTarget - fuelPlan.bingo;
  const loiterTime = (loiterGas / fuelPlan.fuelFlow) * 60;

  const fuelAtLanding = fuelPlan.bingo - fuelConsumed;

  return {
    time,
    distance,
    totalFuel,
    fuelConsumed,
    fuelAtTarget,
    loiterGas,
    loiterTime,
    fuelAtLanding,
  };
};
